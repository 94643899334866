export default function preview() {


  function createPreviewDiv() {
    let $prev = $('#preview');
    if ($prev.length === 0) {
      $('body').append('<div id="preview" class="preview-image"></div>');
      $prev = $('#preview');
      $prev.css({ 'position': 'absolute', 'display': 'none', 'z-index': 1040 });
    }
    return $prev;
  }


  function hidepreview() {
    window.renderPopup = 0;
    $('#preview').html('').fadeOut(0);
  }

  function calculatePositions(clientX, clientY, winwidth, winheight, divwidth, divheight, cursorOffsetHor, cursorOffsetVert, marginSide, marginBottom, scrollTop, e, el) {
    let positionTop = 0;
    let positionLeft = 0;

    if (clientY + divheight + marginBottom + cursorOffsetVert > winheight) {
      if (e != null) {
        positionTop = $(e.currentTarget).offset().top - $(e.currentTarget).height() / 2;
      } else {
        positionTop = $(el).offset().top - $(el).height() / 2;
      }

      if (positionTop < scrollTop) {
        positionTop = scrollTop + 20;
      }

    } else {
      positionTop = clientY + cursorOffsetVert + scrollTop;
    }
    // calculate div position (left)
    if (clientX + divwidth + marginSide + cursorOffsetHor > winwidth) {
      positionLeft = clientX - divwidth - cursorOffsetHor;
    } else {
      positionLeft = clientX + cursorOffsetHor;
    }
    return { 'left': positionLeft, 'top': positionTop }
  }

  window.onHoverTimeOut = null;
  window.renderPopup = 0;
  // window.event = null;

  function drawpreview(el, src, alt, event, timeOut) {

    if (window.innerWidth > 800) {
      const prev = createPreviewDiv();
      const marginSide = 50;
      const marginBottom = 0;
      const cursorOffsetHor = 50;
      const cursorOffsetVert = 0;

      $(prev).html(`<div class="image-preview"><img src="${src}" alt="${alt}"/></div>`);

      const divwidth = $(prev).width() !== 0 ? $(prev).width() : 450;
      const divheight = $(prev).height() !== 0 ? $(prev).height() : 600;
      const winwidth = $(window).width();
      const winheight = $(window).height();
      const scrollTop = $(window).scrollTop();
      const Y = event.pageY;

      let positions = calculatePositions(event.pageX, Y, winwidth, winheight, divwidth, divheight, cursorOffsetHor, cursorOffsetVert, marginSide, marginBottom, scrollTop, null, el);
      prev.css({ 'left': positions.left, 'top': positions.top });
      window.onHoverTimeOut = setTimeout(() => {
        if (window.renderPopup === 1) {
          prev.fadeIn();
        }
      }, timeOut);
      // calculate positions for movement before correct data is loaded
      $(el).mousemove((e) => {
        positions = calculatePositions(e.clientX, Y, winwidth, winheight, divwidth, divheight, cursorOffsetHor, cursorOffsetVert, marginSide, marginBottom, scrollTop, e, null);
        prev.css('left', positions.left).css('top', positions.top);
        // eslint-disable-next-line
        event = e;
      });
    }
  }


  $(document).ready(() => {
    $(document).on('mouseenter', '.js-diplomas-image', (event) => {
      window.renderPopup = 1;
      const image = event.currentTarget;
      const src = image.getAttribute("src");
      const alt = image.getAttribute("alt");
      drawpreview(image, src, alt, event, 200);
      $(document).on('mouseleave', '.js-diplomas-image', () => {
        clearTimeout(window.onHoverTimeOut);
        hidepreview();
      });
    });
  });
}
