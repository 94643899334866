export default function autoPlayHandle (slider) {
  const hasTouch = 'ontouchstart' in window || navigator.maxTouchPoints;
  if (hasTouch) return;

  slider.el.addEventListener('mouseenter', () => {
    slider.autoplay.stop();
  }, false);

  slider.el.addEventListener('mouseleave', () => {
    slider.autoplay.start();
  }, false);
}
