export default function mainOptions (config) {
  const { next, prev, autoPlay, speed } = config;

  const settings = {
    slidesPerView: 1,
    // loopedSlides: slidesLength,
    loop: false,
    threshold: 6,
    navigation: {
      nextEl: next,
      prevEl: prev,
    },
    lazy: {
      loadPrevNext: true,
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
      renderBullet (index, className) {
        return `<span class="${className}">${index + 1}</span>`;
      }
    },
  }

  if (autoPlay) {
    settings.autoplay = {
      delay: Number(autoPlay),
    }
  }

  if (speed) {
    settings.speed = Number(speed)
  }

  return settings
}
