import Swiper from 'swiper/dist/js/swiper'

const productReviewsSlider = (wrapper) => {

  if (!wrapper) return;

  const sliderEl = wrapper.querySelector('.js-reviews-slider');
  const prev = wrapper.querySelector('.js-swiper-button-prev');
  const next = wrapper.querySelector('.js-swiper-button-next');

  const options = {
    init: false,
    threshold: 6,
    spaceBetween: 4,
    slidesPerView: 4,
    navigation: {
      nextEl: next,
      prevEl: prev,
    },
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 3
    },
    breakpoints: {
      768: {
        slidesPerView: 'auto',
      },
      1024: {
        slidesPerView: 3,
      }
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
      renderBullet(index, className) {
        return `<span class="${className}">${index + 1}</span>`;
      }
    }
  };

  const slider = new Swiper(sliderEl, options);
  slider.init();

};
export default productReviewsSlider;
