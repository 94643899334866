import Swiper from 'swiper/dist/js/swiper'

const brandsSlider = (wrapper) => {
  if (!wrapper) return;

  const brandsSliderEl = wrapper.querySelector('.js-brands-slider');
  const slides = Array.prototype.slice.call(brandsSliderEl.querySelectorAll('.swiper-slide'));
  const slidesLength = slides.length;
  const overflowMin = slidesLength > 6;

  const brandsSliderSwiper = new Swiper(brandsSliderEl, {
    init: false,
    slidesPerColumn: 1,
    slidesPerView: 6,
    on: {
      init() {
        if(slidesLength <= 6) {
          $(brandsSliderEl).find('.swiper-wrapper').addClass( "is-disabled" );
          $(brandsSliderEl).find('.swiper-pagination').addClass( "is-disabled" );
        }
      }
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
      renderBullet(index, className) {
        return `<span class="${className}">${index + 1}</span>`;
      }
    },
    breakpoints: {
      320: {
        slidesPerView: overflowMin ? 'auto' : 3,
        slidesPerGroup: overflowMin ? 1 : 3,
        slidesPerColumn: overflowMin ? 1 : 2,
      },
      599: {

        slidesPerView: overflowMin ? 'auto' : 3,
        slidesPerGroup: overflowMin ? 1 : 3,
        slidesPerColumn: overflowMin ? 1 : 2,
      },
      1024: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        slidesPerColumn: 1,
      },
      1200: {
        slidesPerView: 6,
        slidesPerGroup: 6,
        slidesPerColumn: 1,
        pagination: false
      }
    },
  });

  brandsSliderSwiper.init();

};

export default brandsSlider;
