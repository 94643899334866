import Swiper from 'swiper/dist/js/swiper'
import mainOptions from './sliderOptions';
import autoPlayHandle from './autoplayHandle';

const bannerSlider = (wrapper) => {

  if (!wrapper) return;

  const mainSliderEl = wrapper.querySelector('.js-banner-slider-main');
  const slides = Array.prototype.slice.call(mainSliderEl.querySelectorAll('.swiper-slide'));
  const slidesLength = slides.length;
  const prev = wrapper.querySelector('.js-swiper-button-prev');
  const next = wrapper.querySelector('.js-swiper-button-next');

  const autoPlay = $(wrapper).attr('data-autoplay')
  const speed = $(wrapper).attr('data-speed')

  // eslint-disable-next-line
  const mainSlider = new Swiper(mainSliderEl, mainOptions(
    {
      slidesLength,
      prev,
      next,
      autoPlay,
      speed,
    }));

  if (autoPlay) {
    autoPlayHandle(mainSlider)
  }
};
export default bannerSlider;
