import Swiper from 'swiper/dist/js/swiper'
import preview from '../../modules/preview';

const diplomasSlider = (wrapper) => {

  if (!wrapper) return;

  const sliderEl = wrapper.querySelector('.js-diplomas-slider');

  const options = {
    init: false,
    spaceBetween: 16,
    slidesPerView: 6,
    slidesPerGroup: 6,
    threshold: 6,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
      renderBullet(index, className) {
        return `<span class="${className}">${index + 1}</span>`;
      }
    },
    breakpoints: {
      599: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        width: 176,
        spaceBetween: 14,
      },
      1024: {
        slidesPerView: 4,
        slidesPerGroup: 4,
      },
      1200: {
        slidesPerView: 6,
        slidesPerGroup: 6,
      }
    },
  };

  const slider = new Swiper(sliderEl, options);

  slider.on('init', () => {
    preview();
  });

  slider.init();

};
export default diplomasSlider;
