import bannerSlider from './sliders/bannerSlider/bannerSlider';
import diplomasSlider from './sliders/diplomasSlider/diplomasSlider';
import eventsSlider from './sliders/events/eventsSlider';
import productReviewsSlider from './sliders/productReviews/productReviewsSlider';
import productTilesSlider from './sliders/productTiles/productTilesSlider';
import brandsSlider from './sliders/brandsSlider/brandsSlider';

document.addEventListener('DOMContentLoaded', () => {
  window.productTilesSlider = productTilesSlider

  const bannerSliders = Array.prototype.slice.call(document.querySelectorAll('.js-banner-slider'));
  if (bannerSliders.length) {
    bannerSliders.forEach((el) => {
      bannerSlider(el);
    });
  }

  const dilpomasSliders = Array.prototype.slice.call(document.querySelectorAll('.js-banner-slider-wrapper'));
  if (dilpomasSliders.length) {
    dilpomasSliders.forEach((el) => {
      diplomasSlider(el);
    });
  }

  const productTilesSliders = Array.prototype.slice.call(document.querySelectorAll('.js-products-tiles-slider-wrap'));
  if (productTilesSliders.length) {
    productTilesSliders.forEach((el) => {
      productTilesSlider(el);
    });
  }

  const brandsSliderWrapper = Array.prototype.slice.call(document.querySelectorAll('.js-brands-slider-wrapper'));
  if (brandsSliderWrapper.length) {
    brandsSliderWrapper.forEach(el => {
      brandsSlider(el);
    })
  }

  const reviewsSliderWrapper = Array.prototype.slice.call(document.querySelectorAll('.js-reviews-slider-wrap'));
  if (reviewsSliderWrapper.length) {
    reviewsSliderWrapper.forEach(el => {
      productReviewsSlider(el);
    })
  }

  const eventsSliderWrapper = Array.prototype.slice.call(document.querySelectorAll('.js-events-slider-wrapper'));
  if (eventsSliderWrapper.length) {
    eventsSliderWrapper.forEach(el => {
      eventsSlider(el);
    })
  }
});
