import Swiper from 'swiper/dist/js/swiper'

const productTilesSlider = (wrapper) => {

  if (!wrapper) return;

  const sliderEl = wrapper.querySelector('.js-products-tiles-slider');
  const prev = wrapper.querySelector('.js-swiper-button-prev');
  const next = wrapper.querySelector('.js-swiper-button-next');
  const slidesPerView = sliderEl.dataset.slides ? sliderEl.dataset.slides : 4;
  const slidesOnDesktopMd = sliderEl.dataset.slidesMd ? sliderEl.dataset.slidesMd : slidesPerView;
  const slides = Array.prototype.slice.call(sliderEl.querySelectorAll('.swiper-slide'));
  const slidesLength = slides.length;
  const pagination = wrapper.querySelector('.swiper-pagination');

  const loop = (slidesLength >= slidesPerView) || (slidesPerView === 'auto' && slidesLength >= 6);

  if (!loop) {
    sliderEl.classList.add('is-pagination-hidden');
  } else {
    sliderEl.classList.remove('is-pagination-hidden');
  }

  const options = {
    init: false,
    threshold: 6,
    spaceBetween: 0,
    slidesPerView,
    loop,
    observer: true,
    observeParents: true,
    loopAdditionalSlides: slidesLength,
    loopedSlides: slidesLength,
    watchSlidesVisibility: true,
    roundLengths: true,
    navigation: {
      nextEl: next,
      prevEl: prev,
    },
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 3
    },
    on: {
      observerUpdate() {
        if (this.slides.length > slidesPerView) {
          prev.classList.remove('is-hidden');
          next.classList.remove('is-hidden');
        }
      },
      slideChangeTransitionEnd() {
        window.instances.forEach((ins) => {
          ins.update();
        });
      }
    },
    breakpoints: {
      767: {
        slidesPerView: 'auto',
      },
      1023: {
        slidesPerView: 3,
      },
      1220: {
        slidesPerView: slidesOnDesktopMd,
      }
    },
    pagination: {
      el: pagination,
      type: 'bullets',
      clickable: true,
      renderBullet(index, className) {
        return `<span class="${className}">${index + 1}</span>`;
      }
    }
  };

  const slider = new Swiper(sliderEl, options);
  slider.init();

  if (slider.slides.length <= slidesPerView) {
    prev.classList.add('is-hidden');
    next.classList.add('is-hidden');
  }

};

export default productTilesSlider;
