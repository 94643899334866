import Swiper from 'swiper/dist/js/swiper'

const eventsSlider = (wrapper) => {

  if (!wrapper) return;

  const sliderEl = wrapper.querySelector('.js-events-slider');
  const prev = wrapper.querySelector('.js-swiper-button-prev');
  const next = wrapper.querySelector('.js-swiper-button-next');
  const slides = Array.prototype.slice.call(sliderEl.querySelectorAll('.swiper-slide'));
  const slidesLength = slides.length;
  const pagination = wrapper.querySelector('.swiper-pagination');

  const loop = slidesLength > 1;

  if (!loop) {
    pagination.classList.add('is-hidden');
  } else {
    pagination.classList.remove('is-hidden');
  }

  const options = {
    init: false,
    threshold: 6,
    spaceBetween: 20,
    slidesPerView: 1,
    loop,
    loopAdditionalSlides: slidesLength,
    loopedSlides: slidesLength,
    watchSlidesVisibility: true,
    roundLengths: true,
    navigation: {
      nextEl: next,
      prevEl: prev,
    },
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 3
    },
    autoHeight: true,
    pagination: {
      el: pagination,
      type: 'bullets',
      clickable: true,
      renderBullet(index, className) {
        return `<span class="${className}">${index + 1}</span>`;
      }
    }
  };

  const slider = new Swiper(sliderEl, options);
  slider.init();

  if (slider.slides.length <= 1) {
    prev.classList.add('is-hidden');
    next.classList.add('is-hidden');
  }

};

export default eventsSlider;
